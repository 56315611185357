.landing__container {
    width: 100%;
    height: 450px;
    background-image: url('../../../public/images/background_landing.jfif');
    background: linear-gradient(to-right , rgba(0 , 0 , 0 , .6) , rgba(0 , 0 , 0 , .6));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.landing__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

.landing__wrapper > div:first-child {
    margin-top: 2rem;
}

.landing__wrapper > div:last-child {
    margin-bottom: 2rem;
}

.landing__wrapper__text > h3 {
    color: var(--white-color);
    font-family: k;
    font-size: 28px;
    word-spacing: 5px;
}

.landing__wrapper__text > p {
    font-family: s;
    font-size: 16px;
    color: var(--white-color);
    font-weight: 900;
    margin-top: 10px;
}

.landing__wrapper__search {
    margin-top: 1rem;
    width: 100%;
}

.landing__wrapper__search__searchbar {
    position: relative;
    text-align: center;
}

.landing__wrapper__search__searchbar > input {
    width: 650px;
    padding: .8rem;
    font-family: k;
    font-size: 18px;
    border-radius: 6px;
    border: none;
    outline: none;
}

.landing__wrapper__search__searchbar > label {
    position: absolute;
    color: var(--white-color);
    background-color: var(--primary-color);
    height: 80%;
    margin-top: 5px;
    width: 3rem;
    margin-right: -55px;
    border-radius: 8px;
}

.landing__wrapper__search__searchbar > label > svg {
    font-size: 30px;
    margin-top: 7px;
    cursor: pointer;
}

.landing__wrapper__icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.landing__wrapper__icons > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing__wrapper__icons > div > img {
    width: 6rem;
    height: 6rem;
}

.landing__wrapper__icons > div > p {
    color: var(--white-color);
    font-size: 26px;
    font-family: k;
}

.p__nth2 {
    font-size: 22px !important;
    text-align: center;
}

@media screen and (max-width : 750px) {
    .landing__wrapper__search__searchbar > input {
        width: 80%;
    }
    .landing__wrapper__text > h3 , .landing__wrapper__text > p {
        width: 95%;
        text-align: center;
    }

    .landing__wrapper__icons {
        justify-content: space-between;
    }
    .landing__wrapper__icons > div > img {
        width: 4rem;
        height: 4rem;
    }
    .landing__wrapper__icons > div > p {
        font-size: 20px;
    }
    .p__nth2 {
        font-size: 16px !important;
    }
    .landing__wrapper__icons {
        width: 90%;
    }
}

@media screen and (max-width : 500px) {
    .landing__wrapper__search__searchbar > input {
        width: 95%;
        font-size: 14px;
    }
    .landing__wrapper__search__searchbar > label {
        width: 2.5rem;
        margin-right: -45px;
    }
    .landing__wrapper__search__searchbar > label > svg {
        font-size: 22px;
        margin-top: 9px;
    }
    
    .landing__wrapper__text > h3 {
       font-size: 24px;
    }
    .landing__wrapper__text > p {
       font-size: 14px;
    }

    .landing__wrapper__icons > div > img {
        width: 2.5rem;
        height: 2.5rem;
    }
    .landing__wrapper__icons > div > p {
        font-size: 14px;
        margin-top: 5px;
    }
    .p__nth2 {
        font-size: 10px !important;
    }
}