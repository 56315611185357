.coursepage__container {
    width: 100%;
    margin-top: 3rem;
}

.coursepage__wrapper {
    width: var(--w-l);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* roadmap Start */
.coursepage__wrapper__roadmap {
    background-color: #f0f2f7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1.5rem;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.coursepage__wrapper__roadmap > p {
    background-color: var(--white-color);
    margin-left: 1rem;
    padding: 5px;
    border-radius: 8px;
}

.coursepage__wrapper__roadmap > p > svg {
    color: #000;
    font-size: 26px;
    cursor: pointer;
}

.coursepage__wrapper__roadmap > span {
    display: flex;
    align-items: center;
}

.coursepage__wrapper__roadmap > span > a {
    font-family: k;
    font-size: 18px;
    cursor: pointer;
    color: #000;
}

.coursepage__wrapper__roadmap > span > svg {
    margin-left: 6px;
    margin-top: 4px;
    margin-right: 2px;
}
/* roadmap End */

/* course--info-1 Start */
.coursepage__wrapper__course--info-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.coursepage__wrapper__course--info-1__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 1rem;
    flex: 3;
}

.coursepage__wrapper__course--info-1__right > small {
    background-color: var(--primary-color);
    padding: 10px 16px;
    color: var(--white-color);
    font-family: k;
    font-size: 14px;
    border-radius: 6px;
}

.coursepage__wrapper__course--info-1__right > h3 {
    font-family: k;
    font-size: 21px;
}

.coursepage__wrapper__course--info-1__right > p {
    font-family: s;
    font-weight: 500;
    width: 90%;
}

.coursepage__wrapper__course--info-1__right__icons {
    display: flex;
    gap: 10px;
}

.coursepage__wrapper__course--info-1__right__icons > svg {
    font-size: 30px;
    cursor: pointer;
}

.coursepage__wrapper__course--info-1__left {
    margin-top: 2rem;
    flex: 2;
}

.coursepage__wrapper__course--info-1__left > img {
    object-fit: cover;
    width: 100%;
}
/* course--info-1 End */

/* course--info-2 Start */

.coursepage__wrapper__course--info-2 {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
    position: relative;
    width: 100%;
}

.coursepage__wrapper__course--info-2__otherinfo {
    width: calc(100% - 340px);
}

.coursepage__wrapper__course--info-2__sidebar {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    left: 1rem;
}

/* sidebar__one Start */
.coursepage__wrapper__course--info-2__sidebar__one {
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 5px 10px;
}

.coursepage__wrapper__course--info-2__sidebar__one > div {
    padding: 1rem;
    border-radius: 6px;
    position: relative;
    text-align: center;
}

.coursepage__wrapper__course--info-2__sidebar__one > div > button {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    font-family: k;
    font-size: 15px;
    cursor: pointer;
    background-color: transparent;
    color: var(--white-color);
    background-color: var(--primary-color);
}

.coursepage__wrapper__course--info-2__sidebar__one > div > svg {
    font-size: 28px;
    color: var(--white-color);
    cursor: pointer;
    position: absolute;
    right: 3rem;
    top: 1.5rem;
}
/* sidebar__one End */

/* sidebar__two Start */
.coursepage__wrapper__course--info-2__sidebar__two {
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.coursepage__wrapper__course--info-2__sidebar__two__top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    border: 1px solid #888;
    padding: 10px;
    border-radius: 8px;
    margin-top: 1.5rem;
}

.coursepage__wrapper__course--info-2__sidebar__two__top > svg {
    font-size: 26px;
    color: #333;
}

.coursepage__wrapper__course--info-2__sidebar__two__top > span {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-family: k;
}

.coursepage__wrapper__course--info-2__sidebar__two__top > span > p {
    background-color: #333;
    color: var(--white-color);
    font-family: k;
    width: 2.5rem;
    text-align: center;
    border-radius: 6px;
    margin-right: 6px;
}

.coursepage__wrapper__course--info-2__sidebar__two__down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.coursepage__wrapper__course--info-2__sidebar__two__down > span {
    display: flex;
    align-items: center;
    gap: 6px;
}

.coursepage__wrapper__course--info-2__sidebar__two__down > span > svg {
    font-size: 26px;
}

.coursepage__wrapper__course--info-2__sidebar__two__down > span > p {
    font-family: k;
    font-size: 16px;
}

.coursepage__wrapper__course--info-2__sidebar__two__down__1 {
    border-left: 1px solid #333;
    padding-left: 1.5rem;
}

.coursepage__wrapper__course--info-2__sidebar__three {
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.coursepage__wrapper__course--info-2__sidebar__three__top {
    display: flex;
    align-items: center;
    gap: 4px;
}

.coursepage__wrapper__course--info-2__sidebar__three__top > svg {
    font-size: 26px;
    color: #000;
}

.coursepage__wrapper__course--info-2__sidebar__three__top > p {
    font-family: k;
    font-size: 16px;
}

.coursepage__wrapper__course--info-2__sidebar__three__down {
    text-align: center;
    border: 1px solid #888;
    padding: 6px 1rem;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
}

.coursepage__wrapper__course--info-2__sidebar__three__down > p {
    font-family: k;
    font-size: 14px;
}

.coursepage__wrapper__course--info-2__sidebar__four {
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
}

.coursepage__wrapper__course--info-2__sidebar__four > h5 {
    font-family: k;
    font-size: 16px;
}

.coursepage__wrapper__course--info-2__sidebar__four > p {
    font-family: k;
    font-size: 13px;
}

.coursepage__wrapper__course--info-2__sidebar__four > p > a {
    color: rgb(33, 33, 193);
    font-size: 14px;
    cursor: pointer;
}

.coursepage__wrapper__course--info-2__sidebar__five {
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 1rem;
}

.coursepage__wrapper__course--info-2__sidebar__five > h5 {
    font-family: k;
    font-size: 14px;
}

.coursepage__wrapper__course--info-2__sidebar__five > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.coursepage__wrapper__course--info-2__sidebar__five > div > img {
    width: 6rem;
}

.coursepage__wrapper__course--info-2__sidebar__five > div > a {
    font-family: k;
    font-size: 12px;
    color: #000;
}

.coursepage__wrapper__course--info-2__otherinfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.otherinfo__wrapper__box__1 {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 2rem;
}

.otherinfo__wrapper__box__1 > div {
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: start;
}

.otherinfo__wrapper__box__1 > div > svg {
    font-size: 26px;
    color: var(--primary-color);
}

.otherinfo__wrapper__box__1 > div > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    margin-right: 5px;
}

.otherinfo__wrapper__box__1 > div > div > p {
    font-family: k;
    font-size: 14px;
}

.otherinfo__wrapper__box__2 {
    width: 100%;
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 6px;
    margin-top: 1rem;
}

.otherinfo__wrapper__box__2__top {
    display: flex;
    align-items: center;
}

.otherinfo__wrapper__box__2__top > svg {
    font-size: 28px;
    margin-left: 6px;
}

.otherinfo__wrapper__box__2__top > small {
    font-family: k;
    font-size: 16px;
}

.otherinfo__wrapper__box__2__down {
    width: 100%;
    height: 15px;
    border-radius: 1rem;
    background-color: #f0f2f7;
}

.otherinfo__wrapper__box__2__down > p {
    background-color: var(--primary-color);
    width: 70%;
    height: 100%;
    border-radius: 1rem;
}

.otherinfo__wrapper__box__3 { 
    margin-top: 2rem;
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1rem;
}

.otherinfo__wrapper__box__3 > h3 {
    font-family: k;
    font-size: 16px;
    margin: 1rem 0;
    background-color: var(--primary-color);
    color: var(--white-color);
    width: fit-content;
    padding: .5rem;
    border-radius: 6px;
}

.otherinfo__wrapper__box__3 > img {
    width: 100%;
}

.otherinfo__wrapper__box__3 > p {
    margin-top: 1rem;
    font-family: k;
    font-size: 14px;
    margin-bottom: 2rem;
}

.otherinfo__wrapper__box__3__links {
    display: flex;
    align-items: center;
    gap: 10px;
}

.otherinfo__wrapper__box__3__links > button {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-family: k;
    font-size: 14px;
    cursor: pointer;
    padding: 8px 15px;
    outline: none;
    border-radius: 6px;
    background-color: transparent;
    transition: .4s;
}

.otherinfo__wrapper__box__3__links > button:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    transform: scale(1.10);
}

.otherinfo__wrapper__box__4 {
    width: 100%;
    border-radius: 6px;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 2rem;
}

.otherinfo__wrapper__box__4__top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.otherinfo__wrapper__box__4__top__right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.otherinfo__wrapper__box__4__top__right > img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
}

.otherinfo__wrapper__box__4__top__right > span > h5 {
    font-family: k;
    font-size: 16px;
}

.otherinfo__wrapper__box__4__top__right > span > p {
    font-family: k;
    font-size: 12px;
}

.otherinfo__wrapper__box__4__top__left > span {
    background-color: var(--primary-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    padding: 6px 10px;
}

.otherinfo__wrapper__box__4__top__left > span > svg {
    font-size: 24px;
}

.otherinfo__wrapper__box__4__top__left > span > p {
    font-family: k;
    font-size: 14px;
}

.otherinfo__wrapper__box__4__down > p {
    font-family: s;
    font-weight: 600;
    font-size: 14px;
}

/* sidebar__one End */

/* course--info-2 End */

/* media Query Start */

@media screen and (max-width : 1100px) {
    .otherinfo__wrapper__box__1 {
        grid-template-columns: repeat(2 , 1fr);
    }
}

@media screen and (max-width : 1000px) {
    .coursepage__wrapper__course--info-1 {
        flex-direction: column-reverse;
        text-align: center;
        justify-content: center;
    }
    .coursepage__wrapper__course--info-1__right {
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
    }

    .coursepage__wrapper__course--info-2__sidebar {
        width: 270px;
    }
    .coursepage__wrapper__course--info-2__otherinfo {
        width: calc(100% - 310px);
    }

    .coursepage__wrapper__course--info-2__sidebar__one > div > svg {
        font-size: 20px;
        right: 2rem;
        top: 1.6rem;
    }
    .coursepage__wrapper__course--info-2__sidebar__one > div > button {
        font-size: 12px;
    }

    .coursepage__wrapper__course--info-2__sidebar__two__top > svg {
        font-size: 20px;
    }
    .coursepage__wrapper__course--info-2__sidebar__two__top > span {
        font-size: 12px;
    }
    .coursepage__wrapper__course--info-2__sidebar__two__top > span > a {
        font-size: 12px;
    }

    .coursepage__wrapper__course--info-2__sidebar__two__down > span > svg {
        font-size: 20px;
    }
    .coursepage__wrapper__course--info-2__sidebar__two__down > span > p {
        font-size: 10px;
    }
}

@media screen and (max-width : 750px) {
    .coursepage__wrapper__roadmap > p > svg {
        font-size: 14px;
    }
    .coursepage__wrapper__roadmap > span > a {
        font-size: 14px;
    }
    .coursepage__wrapper__roadmap > span > svg {
        font-size: 14px;
    }

    .coursepage__wrapper__course--info-2__sidebar {
        display: none;
    }

    .coursepage__wrapper__course--info-2__otherinfo {
        width: 100%;
    }
}

@media screen and (max-width : 550px) {
    .coursepage__wrapper__roadmap > span > a {
        font-size: 10px;
    }

    .otherinfo__wrapper__box__1 {
        grid-template-columns: repeat(1 , 1fr);
    }

    .otherinfo__wrapper__box__2__top > svg {
        font-size: 21px;
    }
    .otherinfo__wrapper__box__2__top > small {
        font-size: 11px;
    }

    .otherinfo__wrapper__box__3 > h3 {
        font-size: 10px;
    }
    .otherinfo__wrapper__box__3 > p {
        font-size: 10px;
    }
    .otherinfo__wrapper__box__3__links {
        flex-direction: column;
        align-items: flex-start;
    }
    .otherinfo__wrapper__box__3__links > button {
        font-size: 11px;
    }

}