.articlebox__container {
    width: 100%;
    height: 100%;
}

.articlebox__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 1rem;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    padding: 0 1rem;
}

.articlebox__wrapper > img {
    width: 100%;
    height: 100%;
}

.articlebox__wrapper > h5 {
    font-family: k;
    font-size: 19px;
}

.articlebox__wrapper > small {
    font-family: k;
    font-size: 14px;
}

.articlebox__wrapper > a {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 6px 10px;
    border-radius: 6px;
    font-family: k;
    font-size: 16px;
    cursor: pointer;
    transition: .4s;
    margin-bottom: 3rem;
}

.articlebox__wrapper > a:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
}