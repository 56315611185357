.sort {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 2rem;
    width: var(--width-lg);
    margin: auto;
}

@media screen and (max-width : 1100px) {
    .sort {
        grid-template-columns: repeat(2 , 1fr);
    }
}

@media screen and (max-width : 700px) {
    .sort {
        grid-template-columns: repeat(1 , 1fr);
    }
}