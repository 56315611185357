.courses__container {
    width: var(--width-lg);
    margin: auto;
}

.courses__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.courses__wrapper__text {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.courses__wrapper__text__right > h3 {
    font-family: k;
    font-size: 20px;
    background-color: var(--primary-color);
    color: var(--white-color);
    position: relative;
}

.courses__wrapper__text__right > h3::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 20px;
    border-radius: 15px;
    transform: translateZ(45deg);
}

.courses__wrapper__text__right > p {
    font-family: k;
    font-size: 14px;
}

.courses__wrapper__text__left > span {
    position: relative;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 6px;
}

.courses__wrapper__text__left > span > a {
    background-color: transparent;
    padding: 6px;
    font-family: k;
    font-size: 15px;
    cursor: pointer;
    border: none;
    outline: none;
    color: var(--white-color);
}

.courses__wrapper__text__left > span > svg {
    color: var(--white-color) !important;
    font-size: 20px;
}

.courses__wrapper__course {
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 2rem;
}

.coursebox__container {
    margin: auto;
}

@media screen and (max-width : 1100px) {
    .courses__wrapper__course {
        grid-template-columns: repeat(2 , 1fr);
    }
}

@media screen and (max-width : 800px) {
    .courses__container {
        width: var(--width-md);
    }
}

@media screen and (max-width : 600px) {
    .courses__container {
        width: var(--width-sm);
    }
    .courses__wrapper__course {
        grid-template-columns: repeat(1 , 1fr);
        margin: auto;
    }
    
    .courses__wrapper__text__right {
        padding-right: 10px;
    }
    .courses__wrapper__text__right > h3 {
        font-size: 16px;
    }

    .courses__wrapper__text__right > p {
        font-size: 11px;
    }

    .courses__wrapper__text__left > span > a {
        font-size: 12px;
    }
    .courses__wrapper__text__left > span > svg {
        font-size: 16px;
    }
}